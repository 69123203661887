<template>
  <div
    class="form-radio"
    :class="{
      'is-inline': inline
    }"
  >
    <input
      :id="id"
      class="input"
      type="radio"
      :checked="checked"
      :value="value"
      v-bind="$attrs"
      :disabled="!published"
      @change="onChange"
    >

    <label
      :for="id"
      class="label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  name: 'CFormRadio',

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: undefined,
      required: true
    },
    modelValue: {
      type: undefined,
      default: undefined
    },
    inline: {
      type: Boolean
    }
  },

  computed: {
    id: () => nanoid(),
    checked () {
      return this.modelValue === this.value
    },
    published () {
      return this.$store.state.publishedInfo.published
    }
  },

  methods: {
    onChange (event) {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-radio {
  display: flex;
  align-items: flex-start;

  &.is-inline {
    display: inline-flex;
  }
}

.input {
  flex-shrink: 0;
  margin-top: 0.3125em;
}

.label {
  padding-left: 0.5em;
}
</style>
