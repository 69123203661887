<template>
  <div class="form-checkbox">
    <input
      :id="id"
      class="input"
      type="checkbox"
      :checked="checked"
      :value="value"
      v-bind="$attrs"
      :disabled="!published"
      @change="onChange"
    >

    <!-- eslint-disable vue/no-v-html -->
    <label
      :for="id"
      class="label"
      v-html="label"
    />
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  name: 'CFormCheckbox',

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: undefined,
      default: undefined
    },
    modelValue: {
      type: undefined,
      default: false
    }
  },

  computed: {
    id: () => nanoid(),
    checked () {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue
    },
    published () {
      return this.$store.state.publishedInfo.published
    }
  },

  methods: {
    onChange (event) {
      const isChecked = event.target.checked

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-checkbox {
  display: flex;
  align-items: flex-start;
}

.input {
  flex-shrink: 0;
  margin-top: 0.3125em;
}

.label {
  padding-left: 0.5em;
}
</style>
