<template>
  <div
    class="form-columns"
    :style="{
      gridTemplateColumns
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CFormColumns',

  props: {
    columns: {
      type: Array,
      default: () => [1, 1]
    }

  },

  computed: {
    gridTemplateColumns () {
      return this.columns.map(col => `${col}fr`).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-columns {
  @media (min-width: 35rem) {
    display: grid;
    gap: 1.5em;
  }
}
</style>
