<template>
  <div>
    <label
      :for="id"
      class="label mb-2"
    >
      {{ label }}
    </label>

    <p
      v-if="help"
      :id="helpID"
      class="help mb-4"
    >
      {{ help }}
    </p>

    <c-message
      v-if="errors"
      type="error"
      class="errors mb-2"
    >
      <p
        v-for="(error, index) in errors"
        :id="errorsID"
        :key="index"
      >
        {{ error }}
      </p>
    </c-message>
    <select
      ref="input"
      class="input"
      :aria-describedby="ariaDescribedBy"
      :aria-invalid="ariaInvalid"
      v-bind="$attrs"
      :disabled="!published"
      v-on="listeners"
    >
      <option>
        Bildende Kunst
      </option>
      <option>
        Film
      </option>
      <option>
        Interdisziplinär
      </option>
      <option>
        Kinder- und Jugendkultur/kulturelle Bildung
      </option>
      <option>
        Literatur
      </option>
      <option>
        Musik
      </option>
      <option>
        Musiktheater
      </option>
      <option>
        Tanz/Tanztheater
      </option>
      <option>
        Theater/Performance
      </option>
    </select>
    <p
      v-if="file"
      class="mt-3 help"
    >
      <strong>Ausgewählte Datei:</strong> {{ file.name }}
      <button
        class="remove-file"
        type="button"
        @click="clearFile"
      >
        Entfernen
      </button>
    </p>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import CMessage from './CMessage.vue'

export default {
  name: 'CBranchSelect',

  components: {
    CMessage
  },

  inheritAttrs: false,

  props: {
    tag: {
      type: String,
      default: 'input',
      validator: value => [
        'input',
        'textarea'
      ].includes(value)
    },
    label: {
      type: String,
      required: true
    },
    help: {
      type: String,
      default: undefined
    },
    errors: {
      type: Array,
      default: undefined
    },
    file: {
      type: [File, String],
      default: undefined
    },
    options: {
      type: Array,
      default: undefined
    }
  },

  computed: {
    id: () => nanoid(),
    helpID () {
      return `${this.id}-help`
    },
    errorsID () {
      return `${this.id}-error`
    },
    ariaInvalid () {
      return this.error ? 'true' : 'false'
    },
    ariaDescribedBy () {
      const ids = []

      if (this.help) {
        ids.push(this.helpID)
      }

      if (this.error) {
        ids.push(this.errorsID)
      }

      if (ids.length) {
        return ids.join(' ')
      }

      return false
    },
    isFileInput () {
      return this.$attrs.type === 'file'
    },
    listeners () {
      return {
        ...this.$listeners,
        change: event => this.$emit('change', this.isFileInput ? event.target.files[0] : event.target.value, event),
        input: event => this.$emit('input', event.target.value, event)
      }
    },
    published () {
      return this.$store.state.publishedInfo.published
    }
  },

  methods: {
    clearFile () {
      this.$emit('change', '')
      this.$refs.input.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.label {
  display: inline-block;
  font-weight: 700;
}

.help {
  font-size: 0.875em;
  color: variables.$color-gray-700;
}

.input {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375em 0.5em;

  background: variables.$color-gray-100;
  border: 0.125em solid variables.$color-gray-400;
  border-radius: variables.$border-radius;
  outline: 0;

  transition: border-color 0.15s;

  &:focus {
    border-color: variables.$color-gray-900;
  }
}

.errors {
  font-size: 0.875em;
}

.remove-file {
  margin-left: 0.2em;

  color: variables.$color-gray-900;
  background: variables.$color-gray-200;

  border-radius: variables.$border-radius;
}
</style>
